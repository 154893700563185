//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$primary: #309bfd !default;
$secondary: #aeb0b4 !default;
$border: #82868b !default;
$blue: #309bfd !default; //$info
$green: #85c808 !default; //$success
$success:       #85c808 !default;
$warning:       #ea5455 !default;
$accept:       #28c76f !default;
$reject:       #ea5455 !default;
$hover: rgba(8, 168, 255, 0.3) !default;
