.search-pdf-page-view {
  font-size: 14px;
  .search-pdf-view-content {
    height: calc(100vh - 340px);
    .search-pdf-message-details {
      color: $primary;
      cursor: pointer;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 25px;
    }
    .search-pdf-message-details-content {
      color: $danger;
    }
    .search-resolved {
      height: calc(100vh - 140px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .search-pdf-message {
      font-size: 18px;
      color: $black;
    }
  }
  .search-pdf-results {
    width: 100%;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        border-bottom: 1px solid $border-color;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 24px;

        .badge {
          cursor: pointer;
          opacity: 0.7;
          width: 250px;
          padding-top: 15px;
          padding-bottom: 15px;
          font-size: 16px;
          font-family: "Lato";
          font-style: normal;
          font-weight: bold;

          &.active {
            opacity: 1;
            cursor: default;
          }
        }
      }
    }

    .search-pdf-result-compare-document {
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(md) {
    .search-pdf-results {
      ul {
        li {
          .search-pdf-result-title-wrapper {
            flex-direction: column;
          }
          .search-pdf-result-item-action {
            margin-top: 15px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .search-pdf-results {
      ul {
        li {
          .search-pdf-result-title-wrapper {
            flex-direction: column;
            .search-pdf-result-title {
            }
          }
          .search-pdf-result-item-action {
            margin-top: 15px;
            flex-wrap: wrap;
            .badge {
              width: 100%;
              margin-right: 0 !important;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}
