@import "../../../@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "../../../@core/scss/base/components/include"; // Components includes
.loading {
  z-index: 9999;
  width: auto;
  height: auto;
}
.loading-icon {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-message-details {
  color: $primary;
  cursor: pointer;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 25px;
}
.loading-message-details-content {
  color: $danger;
}
