// Component Pagination
// ========================================================================
.pagination {
  &.react-paginate {
    .page-item.prev,
    .page-item.next {
      a.page-link:before,
      a.page-link:after {
        height: 16.5px;
        margin-top: 1px;
      }
    }
    &.pagination-sm {
      .page-item.prev,
      .page-item.next {
        a.page-link:before,
        a.page-link:after {
          height: 15px;
        }
      }
    }
    &.pagination-lg {
      .page-item.prev,
      .page-item.next {
        a.page-link:before,
        a.page-link:after {
          height: 19px;
        }
      }
    }
  }

  &.no-navigation {
    li:first-of-type,
    li:last-of-type {
      display: none;
    }
  }
}

// for mobile
@include media-breakpoint-down(xs) {
  .pagination {
    width: 100%;
    .page-link {
      padding: 7px 4px;
    }
  }
}
