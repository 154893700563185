.auth-wrapper {
  .login-button-wrapper {
    button {
      width: 120px;
      padding-left: 15px;
      padding-right: 15px;
      height: 40px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
    }
  }
}
