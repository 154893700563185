.submissions-page {
  padding: 0.8rem 1.6rem;
  .card {
  }

  .input-group-merge {
    border: solid 1px rgba(0,0,0,.12);
  }
  .header {
    .col {
      @media (max-width: 470px) {
        flex-basis: auto;
        width: 100%;
        a {
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }

  .submissions-dataTable {
    display: flex;
    align-items: flex-start;
    border-radius: 7px;
  }
  .react-dataTable > div{
    border: 1px solid rgba(0,0,0,.12);
  }
  .rdt_TableBody {
    overflow-y: hidden !important;
  }

  .rdt_TableHeadRow {
    font-weight: bold;
    min-height: 32px;
    background-color: rgba(30, 30, 30, 0.12);
    .rdt_TableCol {
      font-size: 14px !important;
      color: $black;
      border-bottom: 1px solid rgba(0,0,0,.12);
      border-left: none;
      &:first-child {
        border-left: none;
      }
      .rdt_TableCol_Sortable {
        cursor: default !important;
        &:hover {
          color: inherit;
        }
      }
      .__rdt_custom_sort_icon__ {
        svg {
          opacity: 1 !important;
        }
        &.asc {
          svg {
            transform: rotate(0deg) !important;
          }
        }
      }
    }
  }
  .rdt_TableRow {
    min-height: 60px;

    &:hover {
      background-color: $hover;
      .action:not(.EXCEPTION) {
        align-items: center;
        font-size: inherit;
        svg {
          display: initial;
        }
      }
    }
    .rdt_TableCell {
      border-left: none;
      &:first-child {
        border-left: none;
      }
      &:nth-child(5) {
        text-transform: lowercase;
      }
    }
    .action {
      font-size: 0;
      svg {
        display: none;
      }
    }
  }

  .pagination.react-paginate {
    .page-item {
      &.prev {
        a.page-link:before {
          margin-right: 5px;
        }
      }
      &.next {
        a.page-link:after {
          margin-left: 5px;
        }
      }
    }
  }
  .bPyPVg{
    max-height: initial;
  }
}

// for mobile small
@media screen and (max-width: 470px) {
  .mt-15 {
    margin-top: 15px;
  }
}

// for tablet
@include media-breakpoint-down(sm) {
  .submissions-page {
    padding: 0;
  }
}
// for mobile
@include media-breakpoint-down(xs) {
  .submissions-page {
    padding: 0;
  }
  .gghHAJ,
  .hYa-DIp {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .PkgOg {
    max-width: 160px !important;
    min-width: 160px !important;
  }
  .eIjVGE {
    min-width: 300px !important;
  }
}
