.select-documents {
  button.uppy-DragDrop-container {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    &:focus {
    }
    .uppy-DragDrop-inner {
      display: none;
    }
  }
  .delete-icon {
    cursor: pointer;
  }
  @include media-breakpoint-down(md) {
    .card {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
  }
}
